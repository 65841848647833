import styled from 'styled-components'

const StyledTop = styled.div`
  width: 100%;
  height: 11.75rem;
  background: #012F57 0% 0% no-repeat padding-box;
  opacity: 1;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 870px) {
    justify-content: flex-start;
    height: auto;
  }
`

export default StyledTop