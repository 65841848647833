import styled from 'styled-components'
import mediaQuery from '../../styles/mediaQuery'
import StyledBottom from './StyledBottom'

const StyledBottomMain = styled(StyledBottom)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 90rem;
  padding: 0 5.75rem 0 5.75rem;
  @media screen and (max-width: 870px) {
    flex-direction: column;
    padding: 0;
    justify-content: center;
  }
  ${mediaQuery('xl')`
    max-width: 78.5rem;
  `}
  ${mediaQuery('lg')`
    padding: 0 5.75rem;
    //max-width: 78.5rem;
  `}
  @media screen and (max-width: 1300px) {
    padding: 0 3%;
  }
`

export default StyledBottomMain 