import styled from 'styled-components'

const StyledLogo = styled.img`
width: 8.5rem;
height: 3.25rem;
margin-bottom: unset;
@media screen and (max-width: 1040px) {
  margin-right: 1.5rem;
}
`
export default StyledLogo