import styled from 'styled-components'

const StyledText = styled.div`
font-family: museo-sans, sans-serif;
font-weight: 500;
font-size: 0.8125rem;
text-align: center;
line-height: 0.9375rem;
letter-spacing: 0px;
color: #E2F1F8;
opacity: 0.7;
`

export default StyledText
