import { useState } from 'react'
import data from './data/footer.json'
import produce, { enableES5 } from 'immer'

const resolveLink = (link: string): string => {
  const len = link.length
  let url: string = link
  if (link.charAt(len - 1) !== '/') url = url.concat('/')
  return url
}

export function useFooter() {

  enableES5()

  const newOptions = produce(data, draft => {
    draft.navitems.forEach(each => {
      if (each.type === 'link') {
        each.url = resolveLink(each.url)
      }
    })
  })

  const [options, setOptions] = useState(newOptions)

  return [
    options, setOptions
  ]
}
