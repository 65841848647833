import styled from 'styled-components'

const StyledBottom = styled.div`
  width: 100%;
  height: 2.8rem;
  background: #052948 0% 0% no-repeat padding-box;
  opacity: 1;
  display:flex;
  justify-content: center;
  @media screen and (max-width: 870px) {
    height: 4.75rem;
    margin-top: 1.21875rem;
  }
`
export default StyledBottom