import styled from 'styled-components'
import mediaQuery from '../../styles/mediaQuery'

const StyledTopMain = styled.div`
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  max-width: 90rem;
  padding: 0 5.75rem;
  ${mediaQuery('lg')`
    padding: 0 5.75rem;
    //max-width: 78.5rem;
  `}
  @media screen and (max-width: 1299px) {
    padding: 0 3%;
  }
  @media screen and (max-width: 1040px) {
    justify-content: center;
  }
  @media screen and (max-width: 870px) {
    padding: 0 1.875rem;
    margin-bottom: 1.75rem;
    margin-top: 2.75rem;
    justify-content: space-between;
  }
`

export default StyledTopMain