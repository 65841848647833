import styled from 'styled-components'
import Link from 'gatsby-link';

const StyledLink = styled.p`
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #FFFFFF;
  opacity: 0.5;
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 870px) {
    text-align: left;
    font-weigth: 700;
    line-height: 1.5625rem;
    letter-spacing: 0px;
    margin-left: 1.875rem;
  }
`
export const LinkContainerExternal = styled.a`
  @media screen and (max-width: 870px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6875rem 0 1.6875rem 0;
    width: 100%;
    border-width:0;
    border-top-width: 1px;
    border-color: #163F62;
    border-style: solid;
  };
  cursor: pointer;
  text-decoration: none;
  &:hover ${StyledLink} {
    cursor: pointer;
    color: #00AFD9;
  }
`

export const LinkContainer = styled(Link as any)`
  @media screen and (max-width: 870px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6875rem 0 1.6875rem 0;
    width: 100%;
    border-width:0;
    border-top-width: 1px;
    border-color: #163F62;
    border-style: solid;
  };
  cursor: pointer;
  text-decoration: none;
  &:hover ${StyledLink} {
    cursor: pointer;
    color: #00AFD9;
  }
`

export default StyledLink