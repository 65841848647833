import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyle from './global-style'
import REMScaling from '../../styles/REMScaling'
import Header from '../header/index'
import Footer from "../Footer";
import { useFooter } from '../../hooks/layout/useFooter';
import { useHeader } from '../../hooks/layout/useHeader';
import ErrorBoundary from '../../utils/errorBoundary';
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

export type Props = {
  title?: string,
  titleSpan?: string,
  isError?: boolean
  hasFooter?: boolean
}

const FontBootstrap = styled.div`
  font-family: MrsSaintDelafield, sans-serif;
  visibility: hidden;
`

const Layout: React.FC<Props> = ({ children, title, titleSpan, isError, hasFooter = true }) => {

  const [footerProps] = useFooter()
  const [headerProps] = useHeader()

  return (
    <ErrorBoundary>
      <Helmet>
        <script>{`
        !function(e){"use strict";function s(s){if(s){var t=e.documentElement;t.classList?t.classList.add("webp"):t.className+=" webp",window.sessionStorage.setItem("webpSupport",!0)}}!function(e){if(window.sessionStorage&&window.sessionStorage.getItem("webpSupport"))s(!0);else{var t=new Image;t.onload=t.onerror=function(){e(2===t.height)},t.src="data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA"}}(s)}(document);
        `}</script>
        <style type="text/css">{`
          @font-face {
              font-family: "Intro";
              src: url("/fonts/Intro.woff") format("woff"),
                url("/fonts/Intro.otf") format("opentype");
              font-display: swap;
          }
          @font-face {
              font-family: "Museo Sans";
              src: url("/fonts/MuseoSans.woff") format("woff"),
                url("/fonts/MuseoSans.otf") format("opentype");
              font-display: swap;
          }
          @font-face {
              font-family: "MrsSaintDelafield";
              src: url("/fonts/MrsSaintDelafield.woff") format("woff"),
                url("/fonts/MrsSaintDelafield.ttf") format("truetype");
              font-display: swap;
          }
        `}</style>
      </Helmet>
      <REMScaling>
        <GlobalStyle />
        <Header
          bannerTitle={title}
          bannerTitleSpan = {titleSpan}
          {...headerProps}
          isError={isError}
        />
        <FontBootstrap/>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '100%'
          }}
        >
          <main
            style={{
              position: 'relative'
            }}
          >
            {children}
          </main>
        </div>
        {hasFooter ? <Footer
          {...footerProps}
        /> : null}
      </REMScaling>
    </ErrorBoundary>
  )
}

export default Layout
