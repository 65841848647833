/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export type Props = {
  description?: string
  lang?: string
  title?: string
  meta?: [{
    name: string
    content: string
  }],
  noindex?: boolean
}

const SEO: React.FC<Props> = ({ description, lang, meta, title, noindex }) => {
  const metaDescription = description

  const indexMeta = {
    name: "robots",
    content: "index,follow"
  }

  const noindexMeta = {
    name: "robots",
    content: "noindex,nofollow"
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: '/social-media-graphic.jpg',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: '/social-media-graphic.jpg',
        },
      ].concat(meta || []).concat(noindex ? noindexMeta : indexMeta)}
    />
  )
}

export default SEO
