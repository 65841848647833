import React from 'react'
import { navigate } from 'gatsby-link'
import * as Sentry from "@sentry/react";
class ErrorBoundary extends React.Component {
    state = {
        errorMessage: ''
    }

    logErrorToSentry: null | ((exception: any, captureContext?: any) => string) = null

    constructor(props: any) {
        super(props)
        if (typeof process.env.GATSBY_CONNECT_TO_DW !== 'undefined') {
            Sentry.init({
                dsn: 'https://5377825bf5f446c8b9e4a9d06ab7cea2@o99321.ingest.sentry.io/5468536',
                environment: process.env.GATSBY_SENTRY_ENV,
                initialScope: {
                  tags: {
                    package: 'join-form',
                  }
                },
            });
            this.logErrorToSentry = Sentry.captureException
        }
    }

    static getDerivedStateFromError(error: any) {
        return {
            errorMessage: error.toString()
        }
    }

    componentDidCatch(error: any) {
        if (typeof process.env.GATSBY_CONNECT_TO_DW !== 'undefined' && this.logErrorToSentry) {
            this.logErrorToSentry(error)
        }
    }

    render() {
        if (this.state.errorMessage) {
            navigate(`/error/`, {
            state: {
                errors: [{message: this.state.errorMessage}]
            }
            })

        }
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

export default ErrorBoundary
